import React, { useRef, useState } from 'react'
import Layout from '../layouts'
import SEO from '../components/seo'
import Image from '../components/image'
import Intro from '../components/intro'
import Content from '../components/content'
import Block from '../components/block'
import { animated } from 'react-spring'
import useIsInViewport from 'use-is-in-viewport'
import useAnimate from '../hooks/useAnimate'
import P from '../components/paragraph'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Resend } from 'resend';

const AnimatedImage = animated(Image)
const AnimatedBlock = animated(Block)

const Rsvp = () => {
  const [formComplete, setFormComplete] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formError, setFormError] = useState(null)
  const fadeIn = useAnimate()
  const travelRef = useRef(null)
  const [isInViewport, wrappedRef] = useIsInViewport({
    target: travelRef,
    modBottom: '-10%'
  })
  const fadeInTrigger = useAnimate({ trigger: isInViewport })

  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const resend = new Resend(process.env.GATSBY_EMAIL_API_KEY);
  const onSubmit = async (formData) => {
    setIsSubmitting(true)
    try {
      const response = await fetch('/api/handler', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // const { name, attending, dietary, song, fullDayEvening } = formData;
      // const { data, error } = await resend.emails.send({
      //   from: "Wedding RSVP <wedding@phelanwedding.co.uk>",
      //   to: ["dandubgraphics@gmail.com"],
      //   subject: "Wedding RSVP",
      //   html: `
      //     <h1>Wedding RSVP</h1><br /><br />
      //     <p>Name: ${name}</p><br />
      //     <p>Full day or evening guest: ${fullDayEvening}</p><br />
      //     <p>Attending: ${attending}</p><br />
      //     <p>Dietary: ${dietary}</p><br />
      //     <p>Song: ${song}</p><br />
      //   `,
      //   headers: {
      //     mode: 'no-cors'
      //   }
      // });



      const responseData = await response.json();
      console.log({ responseData })
      reset()
      setFormComplete(true)
    } catch (error) {
      console.error('Error:', error);
      setFormError('There has been an error submitting the form. Please try again later or give us a call/text.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Layout>
      <SEO title="RSVP" />
      <Intro>
        <h1>RSVP</h1>
        <P narrow>
          Please complete the form below to RSVP to our wedding. We can&apos;t wait to see you there!
        </P>
        <P narrow>
          Please submit one RSVP per person.
        </P>
      </Intro>
      <Content css="margin-top: 2em; margin-left: auto; margin-right: auto" columns center>
        <AnimatedBlock style={fadeIn(500)}>
          {formComplete ? (
            <P narrow center>
              Thank you for RSVPing! We can't wait to see you there. Do you want to <a css={{ cursor: "pointer" }} onClick={() => setFormComplete(false)}>submit another RSVP</a>?
            </P>
          ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <StyledLabel className='no-margin' as='label'>Are you attending the full day or evening?</StyledLabel>
                <CheckboxWrap>
                  <StyledLabel as='label' className='no-margin' labelFor='fullDay'>
                    <input {...register('fullDayEvening', { required: 'Please select if you are attending the full day or just the evening' })} type='radio' id='fullDay' value='Full Day' name='fullDayEvening' />
                    <span>Full day</span>
                  </StyledLabel>

                  <StyledLabel as='label' className='no-margin' labelFor='evening'>
                    <input {...register('fullDayEvening', { required: 'Please select if you are attending the full day or just the evening' })} type='radio' id='evening' value='Evening' name='fullDayEvening' />
                    <span>Evening</span>
                  </StyledLabel>
                </CheckboxWrap>
                {errors.fullDayEvening && <ErrorSpan>{errors.fullDayEvening.message}</ErrorSpan>}
            <StyledLabel className='no-margin' as='label' labelFor='name'>Name</StyledLabel>
            <StyledInput type='text' id='name' {...register('name', { required: 'Please enter your name' })} />
            {errors.name && <ErrorSpan>{errors.name.message}</ErrorSpan>}

            <StyledLabel className='no-margin' as='label' labelFor='dietary'>Dietary Requirements <span>(optional)</span></StyledLabel>
            <StyledTextarea id='dietary' {...register('dietary')} />

            <StyledLabel className='no-margin' as='label' labelFor='song'>Song Request</StyledLabel>
            <StyledInput type='text' id='song' {...register('song', { required: `Send in a jam! We won't judge.` })} />
            {errors.song && <ErrorSpan>{errors.song.message}</ErrorSpan>}

            <StyledLabel className='no-margin' as='label'>Are you able to attend?</StyledLabel>
            <CheckboxWrap>
              <StyledLabel as='label' className='no-margin' labelFor='yes'>
                <input {...register('attending', { required: 'Please select if you are attending or not' })} type='radio' id='yes' value='yes' name='attending' />
                <span>I am attending!</span>
              </StyledLabel>

              <StyledLabel as='label' className='no-margin' labelFor='no'>
                <input {...register('attending', { required: 'Please select if you are attending or not' })} type='radio' id='no' value='no' name='attending' />
                <span>I cannot attend</span>
              </StyledLabel>
            </CheckboxWrap>

            {errors.attending && <ErrorSpan>{errors.attending.message}</ErrorSpan>}

            <SubmitButton type="submit">
                  {isSubmitting ? 'Sending...' : 'Submit'}
            </SubmitButton>
                {formError && <ErrorSpan>{formError}</ErrorSpan>}
          </form>
          )}
        </AnimatedBlock>
      </Content>

    </Layout>
  )
}

export default Rsvp

const inputStyles = css`
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.light};
  padding: .75rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: none;
`

const ErrorSpan = styled.span`
  color: #ff3333;
  font-size: .85rem;
`

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;

  & > label {
    width: 100%;
    margin-top: 0;

    &:first-child span {
      border-right: 0;
    }
  }
`

const StyledLabel = styled(P)`
  font-family: ${({ theme }) => theme.font.body};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 1.1rem;
  margin-bottom: 5px;
  display: block;

  input + span {
    ${inputStyles}
    width: 100%;
    margin-left: 0;
    display: block;
    text-align: center;
    cursor: pointer;
  }

  input:checked + span {
    background-color: rgb(34, 34, 34);
    color: #fff;
  }

  input {
    display: none;
  }

  span {
    font-size: .85rem;
    margin-left: .25rem;
  }
`

const StyledInput = styled.input`
  ${inputStyles}
`

const StyledTextarea = styled.textarea`
  ${inputStyles}
  min-height: 150px;
  display: block;
`

const SubmitButton = styled.button`
  appearance: none;
  letter-spacing: 0.25rem;
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.15s ease 0s, color 0.15s ease 0s;
  font-size: 0.813rem;
  line-height: 1.25;
  text-align: center;
  border: 1px solid rgb(34, 34, 34);
  color: rgb(34, 34, 34);
  width: 100%;
  margin-top: 25px;
  display: inline-block;
  transition: all .15s ease-in-out;

  &:hover {
    background-color: rgb(34, 34, 34);
    color: #fff;
  }
`